//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ImageCopyright from '../Partials/ImageCopyright.vue';

export default {
    name: 'LandingStage',
    components: {ImageCopyright},
    props: ['data'],
    data() {
        return {
            header: null,
            timeout: 0,
            imagePath: this.$config.pimcoreUrl,
            calcHeight: false,
            isMobile: !!this.$mobileDetect.mobile(),
        };
    },
    computed: {
        image() {
            if (!this.data?.image) return null;
            return this.$mapImageData(this.data.image.image);
        },
        imageMobile() {
            if (!this.data?.imageMobile) return null;
            return this.$mapImageData(this.data.imageMobile.image);
        },
    },
    mounted() {
        this.$nextTick(() => {
            if (!this.$refs.h2) return (this.calcHeight = true);
            const height = this.$refs.h2.clientHeight > this.$refs.h1.clientHeight ? this.$refs.h2.clientHeight : this.$refs.h1.clientHeight;
            this.$refs.wrapper.style.height = `${height}px`;
            this.calcHeight = true;
        });
    },
};
